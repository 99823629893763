import { Frame, Modal, Text } from '@shopify/polaris'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export const DeleteBomConfirmationModal = ({
  open,
  setIsOpen,
  onDelete
}) => {
  const { t } = useTranslation()

  const handleCloseModal = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const handleDeleteModal = useCallback(() => {
    setIsOpen(false)
    onDelete()
  }, [setIsOpen, onDelete])

  return (
    <div style={{ height: '500px' }}>
      <Frame>
        <Modal
          open={open}
          onClose={handleCloseModal}
          title={t('productions.confirm_bom_deletion_modal.title')}
          primaryAction={{
            content: t('productions.confirm_bom_deletion_modal.primary_action'),
            onAction: handleDeleteModal,
            tone: 'critical'
          }}
          secondaryActions={[
            {
              content: t('productions.confirm_bom_deletion_modal.secondary_action'),
              onAction: handleCloseModal
            }
          ]}
        >
          <Modal.Section>
            <Text>
              {t('productions.confirm_bom_deletion_modal.description')}
            </Text>
          </Modal.Section>
        </Modal>
      </Frame>
    </div>
  )
}
