import { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { formatTooltip } from '@shared/utils/tooltipFormatter'
import {
  Card,
  Text,
  Select,
  BlockStack,
  Tooltip,
  Box
} from '@shopify/polaris'

export const StatusCard = ({ onChange, initialValue }) => {
  const { t } = useTranslation()
  const [selected, setSelected] = useState(initialValue)

  const handleSelectChange = useCallback((value) => {
    setSelected(value)
    onChange(value)
  }, [onChange])

  const options = [
    {
      label: t('status_card.statuses.active'),
      value: 'active'
    },
    {
      label: t('status_card.statuses.draft'),
      value: 'draft'
    }
  ]

  return (
    <Card>
      <BlockStack gap='200' inlineAlign='start'>
        <Tooltip
          width='wide'
          content={
            <Box
              padding='200'
              paddingBlockStart='300'
              paddingBlockEnd='300'
            >
              <BlockStack gap='300'>
                <Text variant="headingMd">
                  {t('status_card.title')}
                </Text>
                <Text>
                  {formatTooltip(
                    t('status_card.tooltip'),
                    [
                      t('status_card.statuses.draft'),
                      t('status_card.statuses.active')
                    ]
                  ).map((line, index) => (
                    <Text key={index + line}>{line}</Text>
                  ))}
                </Text>
              </BlockStack>
            </Box>
          }
          hasUnderline
          preferredPosition='below'
        >
          <Text as="h2" variant="headingMd">
            {t('status_card.title')}
          </Text>
        </Tooltip>
      </BlockStack>
      <Box paddingBlockStart='300'>
        <Select
          label=''
          options={options}
          value={selected}
          onChange={handleSelectChange}
        />
      </Box>
    </Card>
  )
}
