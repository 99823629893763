import '@shopify/polaris/build/esm/styles.css'
import { useContext, useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { backendRequest } from '@shared/utils/axiosInstance'
import { DataUpdateContext } from '@shared/context/context'
import { PlanCard } from '@shared/components/PlanCard'
import { useTranslation } from 'react-i18next'
import {
  Page,
  InlineStack,
  Card,
  SkeletonPage,
  Box,
  BlockStack
} from '@shopify/polaris'
import BillingInterval from '@shared/components/BillingInterval'
import { BILLING_INTERVAL } from '@shared/utils/constant'
import { convertPlanPriceToText, saveMoney } from '@shared/utils'

export const PlanSelectionView = () => {
  const { companyId, appBridge, subscriptionPlan } = useContext(DataUpdateContext)
  const [loadingPremiumPlan, setLoadingPremiumPlan] = useState(false)
  const [loadingFreePlan, setLoadingFreePlan] = useState(false)
  const [billingInterval, setBillingInterval] = useState(BILLING_INTERVAL.YEARLY.id)
  const [priceFactor, setPriceFactor] = useState(BILLING_INTERVAL.YEARLY.factor)
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  const handleMonthlyClick = useCallback(() => {
    if (billingInterval === BILLING_INTERVAL.MONTHLY.id) return
    setBillingInterval(BILLING_INTERVAL.MONTHLY.id)
    setPriceFactor(BILLING_INTERVAL.MONTHLY.factor)
  }, [billingInterval])

  const handleYearlyClick = useCallback(() => {
    if (billingInterval === BILLING_INTERVAL.YEARLY.id) return
    setBillingInterval(BILLING_INTERVAL.YEARLY.id)
    setPriceFactor(BILLING_INTERVAL.YEARLY.factor)
  }, [billingInterval])

  const handleFreePlanSelection = async () => {
    setLoadingFreePlan(true)
    try {
      const { data: { return_url: returnUrl } } = await backendRequest({
        method: 'POST',
        url: '/shopify/subscriptions',
        appBridge,
        data: {
          company_id: companyId,
          plan: 'free',
          billing_interval: billingInterval
        }
      })
      navigate(`/shopify/subscription/return?${returnUrl.split('?')[1]}`)
    } catch (error) {
      console.error('Failed to create free subscription.', error)
    } finally {
      setLoadingFreePlan(false)
    }
  }

  const handlePremiumPlanSelection = async () => {
    setLoadingPremiumPlan(true)
    try {
      const { data: { confirmation_url: confirmationUrl } } = await backendRequest({
        method: 'POST',
        url: '/shopify/subscriptions',
        appBridge,
        data: {
          company_id: companyId,
          plan: 'premium',
          billing_interval: billingInterval
        }
      })
      if (confirmationUrl) {
        window.open(confirmationUrl, '_top')
      }
    } catch (error) {
      console.error('Failed to create premium subscription.', error)
    } finally {
      setLoadingPremiumPlan(false)
    }
  }

  const premiumPlanButtonLabel = subscriptionPlan === 'premium' ? t('button.current_plan') : t('button.upgrade')
  const isPremiumPlanDisabled = subscriptionPlan === 'premium'
  const freePlanButtonLabel = subscriptionPlan === 'free' ? t('button.current_plan') : t('button.downgrade')
  const isFreePlanDisabled = subscriptionPlan === 'free' || !subscriptionPlan

  const premiumPlanItems = [
    t('plan.bom.starter.items.stock_adjustment_material'),
    t('plan.bom.starter.items.stock_adjustment_bundle'),
    t('plan.bom.starter.items.materials'),
    t('plan.bom.starter.items.bundles'),
    t('plan.bom.starter.items.productions')
  ]

  const freePlanItems = [
    t('plan.bom.free.items.stock_adjustment_material'),
    t('plan.bom.free.items.stock_adjustment_bundle'),
    t('plan.bom.free.items.materials'),
    t('plan.bom.free.items.bundles'),
    t('plan.bom.free.items.productions')
  ]

  return (
    <Page>
      { companyId
        ? (
          <BlockStack
            align='center'
            inlineAlign='center'
            gap='500'
          >
            <BillingInterval
              billingInterval={billingInterval}
              handleMonthlyClick={handleMonthlyClick}
              handleYearlyClick={handleYearlyClick}
            />
            <InlineStack gap="400" align="center">
              <PlanCard
                title={t('plan.bom.free.title')}
                price={
                  convertPlanPriceToText(
                    t('plan.bom.free.price'),
                    t('plan.bom.free.price'),
                    priceFactor,
                    i18n.language
                  )
                }
                priceFactor={priceFactor}
                items={freePlanItems}
                buttonLabel={freePlanButtonLabel}
                handleLoading={loadingFreePlan}
                handlePlanSelection={handleFreePlanSelection}
                disabled={isFreePlanDisabled}
              />
              <PlanCard
                title={t('plan.bom.starter.title')}
                price={
                  convertPlanPriceToText(
                    t('plan.bom.starter.price'),
                    t('plan.bom.free.price'),
                    priceFactor,
                    i18n.language
                  )
                }
                priceFactor={priceFactor}
                frequency={t('plan.bom.starter.month')}
                saveMoney={saveMoney(t('plan.bom.starter.price'), priceFactor, t('plan.save_money'), i18n.language.startsWith('en'))}
                isSaveMoneyShown={billingInterval === BILLING_INTERVAL.YEARLY.id}
                items={premiumPlanItems}
                trial={t('plan.bom.starter.trial')}
                buttonLabel={premiumPlanButtonLabel}
                handleLoading={loadingPremiumPlan}
                handlePlanSelection={handlePremiumPlanSelection}
                disabled={isPremiumPlanDisabled}
              />
            </InlineStack>
          </BlockStack>
          )
        : (
          <SkeletonPage>
            <InlineStack gap="400" align="center">
              <Card>
                <Box minHeight='100px' minWidth="350px" padding='300' />
              </Card>
              <Card>
                <Box minHeight='100px' minWidth="350px" padding='300' />
              </Card>
            </InlineStack>
          </SkeletonPage>
          )
      }
    </Page>
  )
}
