import { parse } from 'papaparse'

const flattenObject = (obj, prefix = '') => {
  return Object.keys(obj).reduce((acc, key) => {
    const fullKey = prefix ? `${prefix}.${key}` : key
    const value = obj[key]

    if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
      Object.assign(acc, flattenObject(value, fullKey))
    } else if (Array.isArray(value)) {
      value.forEach((item, index) => {
        Object.assign(acc, flattenObject(item, `${fullKey}[${index}]`))
      })
    } else {
      acc[fullKey] = value
    }

    return acc
  }, {})
}

const reconstructObject = (flatObject) => {
  const reconstructed = {}

  Object.keys(flatObject).forEach((key) => {
    const keys = key.split('.')

    keys.reduce((acc, currKey, index) => {
      if (!acc[currKey]) {
        acc[currKey] = index === keys.length - 1 ? flatObject[key] : {}
      }

      return acc[currKey]
    }, reconstructed)
  })

  return reconstructed
}

export const formatBomsToCsv = (boms) => {
  const flattenedBoms = boms.map((bom) => flattenObject(bom))
  const headers = Array.from(
    new Set(flattenedBoms.reduce((allKeys, bom) => allKeys.concat(Object.keys(bom)), []))
  ).sort()
  const csvRows = [
    headers.join(','),
    ...flattenedBoms.map((bom) => headers.map((header) => bom[header] || '').join(','))
  ]

  return csvRows.join('\n')
}

export const parseCsvToObjects = (csvData) => {
  const { data, errors } = parse(csvData, {
    header: true,
    skipEmptyLines: true
  })

  if (errors.length) throw new Error('CSV parsing error: ' + errors.map((e) => e.message).join(', '))

  return data.map((row) => reconstructObject(row))
}
