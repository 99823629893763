import { useState, useCallback } from 'react'
import {
  Card,
  Text,
  BlockStack,
  ChoiceList,
  Tooltip,
  Box
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { formatTooltip } from '@shared/utils/tooltipFormatter'

export const InventoryType = ({
  onChange,
  initialValue = 'bundled_product'
}) => {
  const { t } = useTranslation()
  const [selected, setSelected] = useState([initialValue])

  const handleChange = useCallback((value) => {
    onChange(value)
    setSelected(value)
  }, [onChange])

  const choices = [
    {
      label: t('inventory_status_choice_list.statuses.bundled_product'),
      value: 'bundled_product'
    },
    {
      label: t('inventory_status_choice_list.statuses.finished_product'),
      value: 'finished_product'
    }
  ]

  return (
    <Card>
      <BlockStack gap='200' inlineAlign='start'>
        <Tooltip
          width='wide'
          content={
            <Box
              padding='200'
              paddingBlockStart='300'
              paddingBlockEnd='300'
            >
              <BlockStack gap='300'>
                <Text variant="headingMd">
                  {t('inventory_status_choice_list.title')}
                </Text>
                <Text>
                  {formatTooltip(
                    t('inventory_status_choice_list.tooltip'),
                    [
                      t('inventory_status_choice_list.statuses.bundled_product'),
                      t('inventory_status_choice_list.statuses.bundled_product'),
                      t('inventory_status_choice_list.statuses.finished_product')
                    ]
                  ).map((line, index) => (
                    <Text key={index + line}>{line}</Text>
                  ))}
                </Text>
              </BlockStack>
            </Box>
          }
          hasUnderline
          preferredPosition='below'
        >
          <Text variant="headingMd">
            {t('inventory_status_choice_list.title')}
          </Text>
        </Tooltip>
        <ChoiceList
          choices={choices}
          selected={selected}
          onChange={handleChange}
        />
      </BlockStack>
    </Card>
  )
}
