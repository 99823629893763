import { useState, useEffect, useContext, useMemo, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { addDays, format } from 'date-fns'
import { backendRequest } from '@shared/utils/axiosInstance'
import { currencyFormatter, integerFormatter, formatter } from '@shared/utils/formatter'
import { setDefaultFilterSelectedValues } from '@shared/utils'
import '@shared/styles/ag-grid.css'
import { DataUpdateContext } from '@shared/context/context'
import dateFilterParams from '@standalone/components/agGridParams/dateFilter'
import { AgGridTable } from '@standalone/components/common/AGGridTable'
import { ExportButton } from '@standalone/components/agGridParams/buttons/ExportButton'
import { ResetButton } from '@standalone/components/agGridParams/buttons/ResetButton'
import {
  Select,
  Tag,
  Text,
  Box,
  InlineGrid,
  BlockStack,
  Banner,
  ProgressBar,
  Icon,
  Tooltip,
  InlineStack,
  Page
} from '@shopify/polaris'
import { LockIcon } from '@shopify/polaris-icons'
import { InventoryCard } from '@replen/components/InventoryCard'
import {
  LOCATION_NAME_DEFAULT_NOT_IN_FILTER,
  PRODUCT_STATUS_DEFAULT_NOT_IN_FILTER,
  INVENTORY_TRACKED_DEFAULT_NOT_IN_FILTER,
  DEFAULT_LEAD_TIME,
  DEFAULT_MOQ,
  DEFAULT_MIN_DAYS_INVENTORY,
  REPLENISHMENT_NOW_DAY_THRESHOLD,
  DAILY_VELOCITY_THRESHOLD,
  REPLENISHMENT_SOON_DAY_THRESHOLD,
  HELP_DESK_URL,
  SUBSCRIPTION_PLAN,
  LARGE_REQUEST_LIMIT
} from '@shared/utils/constant'
import { UpgradeButton } from '@shared/components/UpgradeButton'
import { UpgradeBanner } from '@shared/components/UpgradeBanner'
import { FREE_PLAN_LIMIT } from '@src/apps/bom/utils/constants'

const ReplenishmentView = () => {
  const { state } = useLocation()
  const filter = state?.filter || 'replenish_now'
  const { lastUpdate, companyId, appBridge, subscriptionPlan, steps, jobStatus, companyParams, setThresholdsRequestHeaders } = useContext(DataUpdateContext)
  const gridApi = useRef(null)
  const { t } = useTranslation()
  const [products, setProducts] = useState(null)
  const [replenishSoonData, setReplenishSoonData] = useState(null)
  const [replenishNowData, setReplenishNowData] = useState(null)
  const [overStockData, setOverStockData] = useState(null)
  const [deadStockData, setDeadStockData] = useState(null)
  const [ok, setOk] = useState(null)
  const [locations, setLocations] = useState([])
  const [selectedLocation, setSelectedLocation] = useState('')
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [showBanner, setShowBanner] = useState(false)
  const [showErrorBanner, setShowErrorBanner] = useState(false)
  const [progress, setProgress] = useState(null)
  const [isFreeSubscriptionPlan, setIsFreeSubscriptionPlan] = useState(true)
  const [isUpgradeBannerDismissed, setIsUpgradeBannerDismissed] = useState(false)

  useEffect(() => {
    setIsFreeSubscriptionPlan(subscriptionPlan === SUBSCRIPTION_PLAN.FREE)
  }, [subscriptionPlan])

  const expandGroup = (inventoryStatus) => {
    if (gridApi.current) {
      gridApi.current.forEachNode((node) => {
        if (node.group) {
          if (node.key === inventoryStatus) {
            node.setExpanded(true)
          } else {
            node.setExpanded(false)
          }
        }
      })
    }
  }

  const handleSelectChange = (value) => {
    setSelectedLocation(value)
  }
  const toggleDropdownOpen = () => setDropdownOpen((prev) => !prev)

  const options = Array.isArray(locations)
    ? locations.map(loc => ({
      label: loc.location_name,
      value: loc.location_id
    }))
    : []

  const gridOptions = useMemo(() => ({
    isGroupOpenByDefault: params => {
      return (params.field === 'inventory_status' && (params.key === filter))
    }
  }), [filter])

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      wrapHeaderText: true,
      // autoHeaderHeight: true,
      suppressMenu: true
    }
  }, [])

  const onGridReady = useCallback(async (params) => {
    gridApi.current = params.api
    gridApi.current.onFilterChanged()
  }, [gridApi])

  const initialGroupOrderComparator = useCallback(
    (params) => {
      const order = [
        'replenish_now',
        'replenish_soon',
        'overstock',
        'deadstock',
        'ok'
      ]
      const a = params.nodeA.key || ''
      const b = params.nodeB.key || ''
      return order.indexOf(a) < order.indexOf(b) ? -1 : order.indexOf(a) > order.indexOf(b) ? 1 : 0
    },
    []
  )

  const checkExpandAll = () => {
    if (!gridApi.current || !isFreeSubscriptionPlan) return
    gridApi.current.forEachNode(node => {
      if (!node.group) return
      node.setExpanded(true)
    })
    gridApi.current.redrawRows()
  }

  useEffect(() => {
    if (!companyId) return
    const fetchData = async () => {
      try {
        const response = await backendRequest({
          method: 'get',
          url: `/obius/locations?company_id=${companyId}`,
          appBridge
        })
        if (!response.data) {
          throw new Error('Failed to fetch locations')
        }
        setLocations([{ location_name: t('product.all_locations'), location_id: '' }, ...response.data.locations])
      } catch (error) {
        console.error('Error fetching locations:', error)
      }
    }
    fetchData().catch(error => {
      console.error('Error in fetchData:', error)
    })
  }, [t, companyId, appBridge])

  useEffect(() => {
    if (!lastUpdate || !companyParams) {
      return
    }

    const sort = subscriptionPlan === SUBSCRIPTION_PLAN.FREE ? 'inventory_status,-loss_sales' : '-loss_sales'

    const params = new URLSearchParams({
      fields: 'sell_price,inventory_status,product_name,product_variant_name,product_sku,is_material,product_supplier,moq,lead_time,min_days_inventory,location_name,is_inventory_tracked,product_status',
      populate: 'product,location',
      pivotColumns: 'metric_name',
      pivotValue: 'metric_value',
      metric_name: 'day_inventory,inventory_value,daily_velocity,loss_sales,inventory_quantity,quantity_to_order,next_order_date',
      metric_date: lastUpdate.split('T')[0],
      company_id: companyId,
      sort,
      limit: LARGE_REQUEST_LIMIT
    })

    if (selectedLocation) {
      params.append('location_id', selectedLocation)
    } else {
      // params.append('!location_id', '')
    }
    const queryParams = new URLSearchParams(params)

    const fetchData = async () => {
      try {
        const response = await backendRequest({
          method: 'get',
          url: `/obius/metrics?${queryParams.toString()}`,
          appBridge,
          requestHeaders: setThresholdsRequestHeaders(companyParams)
        })

        const { metrics } = response.data
        subscriptionPlan === SUBSCRIPTION_PLAN.FREE ? setProducts(metrics.slice(0, FREE_PLAN_LIMIT)) : setProducts(metrics)

        const prepareCardData = (filter, aggregationColumn) => {
          const data = metrics.filter((
            {
              inventory_status: inventoryStatus,
              product_status: productStatus,
              is_inventory_tracked: isInventoryTracked,
              location_name: locationName
            }) => (
            inventoryStatus === filter &&
            !PRODUCT_STATUS_DEFAULT_NOT_IN_FILTER.includes(productStatus) &&
            !INVENTORY_TRACKED_DEFAULT_NOT_IN_FILTER.includes(isInventoryTracked) &&
            !LOCATION_NAME_DEFAULT_NOT_IN_FILTER.includes(locationName)
          ))

          const total = data.reduce((acc, { [aggregationColumn]: value = 0 }) => acc + +value, 0)

          return {
            total_products: data.length,
            total_sell_price: total,
            total_inventory_value: total,
            product_list: data,
            column: aggregationColumn
          }
        }

        if (metrics) {
          setReplenishNowData(prepareCardData('replenish_now', 'loss_sales'))
          setReplenishSoonData(prepareCardData('replenish_soon', 'loss_sales'))
          setOverStockData(prepareCardData('overstock', 'inventory_value'))
          setDeadStockData(prepareCardData('deadstock', 'inventory_value'))
          setOk(prepareCardData('ok', 'inventory_value'))
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData().catch(error => {
      console.error('Error in fetchData:', error)
    })
  }, [lastUpdate, companyId, setReplenishNowData, setReplenishSoonData, setOverStockData, setDeadStockData, setOk, appBridge, selectedLocation, companyParams, setThresholdsRequestHeaders, subscriptionPlan])

  useEffect(() => {
    if (lastUpdate) setShowBanner(false)
    if (steps && steps.length < 5) {
      setProgress(steps.length / 5 * 100)
      setShowBanner(true)
    }
  }, [steps, lastUpdate])

  useEffect(() => {
    if (jobStatus === 'failed') {
      setShowBanner(false)
      setShowErrorBanner(true)
      setProducts([])
    }
  }, [jobStatus])

  const onFirstDataRendered = async () => {
    await setDefaultFilterSelectedValues({ filterName: 'location_name', unselectedValues: LOCATION_NAME_DEFAULT_NOT_IN_FILTER, gridApi })
    await setDefaultFilterSelectedValues({ filterName: 'is_inventory_tracked', unselectedValues: INVENTORY_TRACKED_DEFAULT_NOT_IN_FILTER, gridApi })
    await setDefaultFilterSelectedValues({ filterName: 'product_status', unselectedValues: PRODUCT_STATUS_DEFAULT_NOT_IN_FILTER, gridApi })
    checkExpandAll()
    gridApi.current.onFilterChanged()
  }

  const columns = useMemo(
    () => {
      const getQuantityToOrder = ({ inventoryStatus, dailyVelocity, minDaysInventory, leadTime, moq }) => {
        if (['overstock', 'deadstock'].includes(inventoryStatus)) return null
        const actualMinDaysInventory = (minDaysInventory != null && minDaysInventory >= 0) ? +minDaysInventory : (companyParams.minDaysInventory != null && companyParams.minDaysInventory >= 0) ? +companyParams.minDaysInventory : DEFAULT_MIN_DAYS_INVENTORY
        const actualLeadTime = (leadTime != null && leadTime >= 0) ? +leadTime : (companyParams.leadTime != null && companyParams.leadTime >= 0) ? +companyParams.leadTime : DEFAULT_LEAD_TIME
        const actualMoq = (moq != null && moq >= 0) ? +moq : (companyParams.moq != null && companyParams.moq >= 0) ? +companyParams.moq : DEFAULT_MOQ
        return Math.max(Math.ceil(+dailyVelocity * (actualMinDaysInventory + actualLeadTime)), actualMoq)
      }

      const getNextOrderDate = ({ inventoryStatus, dayInventory, minDaysInventory, moq, lastUpdate }) => {
        if (['overstock', 'deadstock'].includes(inventoryStatus)) return null
        const actualMoq = (moq != null && moq >= 0) ? +moq : (companyParams.moq != null && companyParams.moq >= 0) ? +companyParams.moq : DEFAULT_MOQ
        const actualMinDaysInventory = (minDaysInventory != null && minDaysInventory >= 0) ? +minDaysInventory : (companyParams.minDaysInventory != null && companyParams.minDaysInventory >= 0) ? +companyParams.minDaysInventory : DEFAULT_MIN_DAYS_INVENTORY
        return format(addDays(lastUpdate, Math.floor(Math.max(+dayInventory - actualMoq - actualMinDaysInventory, 0))), 'yyyy-MM-dd')
      }

      const productNameValueGetter = (params) => {
        const productName = params.data?.product_name || ''
        const productVariantName = params.data?.product_variant_name || ''
        return `${productName}\n${productVariantName}`
      }

      const productNameRenderer = ({ value }) => {
        const [productName, productVariantName] = value.split('\n')
        return (
          <div>
            <BlockStack>
              <Text variant='headingMd' fontWeight='bold'>{productName}</Text>
              <div>
                <Tag>
                  {productVariantName}
                </Tag>
              </div>
            </BlockStack>
          </div>
        )
      }

      const productNameFilterRenderer = ({ value }) => {
        const [productName, productVariantName] = value.split('\n')
        return (
          <Box>
            <InlineStack gap='200' wrap={false}>
              <Text variant='headingMd' fontWeight='bold'>{productName}</Text>
              <div>
                <Tag>
                  {productVariantName}
                </Tag>
              </div>
            </InlineStack>
          </Box>
        )
      }

      const dailyVelocityRenderer = ({ value }) => {
        const { dailySales, dailyVelocity } = value || {}
        if (!dailyVelocity) return null
        return (
          <BlockStack inlineAlign='center' align='center'>
            <Text variant='headingMd' fontWeight='bold'>{currencyFormatter.format(dailySales)}</Text>
            <Text variant='headingMd' tone="subdued">
              {dailyVelocity !== undefined ? formatter.format(+dailyVelocity) : null} {t('units.units')}
            </Text>
          </BlockStack>
        )
      }

      // const dailyVelocityFilterRenderer = ({ value }) => {
      //   const { dailySales, dailyVelocity } = value || {}
      //   console.debug('dailyVelocityFilterRenderer', dailyVelocity)
      //   if (!dailyVelocity) return null
      //   return (<div>{dailyVelocity}</div>)
      // }

      return [
        {
          headerName: t('product.inventory_status'),
          field: 'inventory_status',
          rowGroup: true,
          enableRowGroup: true,
          filter: 'agSetColumnFilter',
          pinned: 'left',
          valueFormatter: ({ value }) => t(`dashboard.${value}`),
          hide: true
        },
        // PRODUCT
        {
          headerName: t('product.name'),
          field: 'product_name',
          hide: false,
          autoHeight: true,
          cellStyle: { textAlign: 'left' },
          valueGetter: productNameValueGetter,
          cellRenderer: productNameRenderer,
          filterParams: {
            cellRenderer: productNameFilterRenderer
          },
          filter: 'agSetColumnFilter'
        },
        // SKU
        {
          headerName: t('product.sku'),
          hide: false,
          field: 'product_sku',
          filter: 'agSetColumnFilter',
          cellStyle: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }
        },
        // location
        {
          headerName: t('product.location'),
          field: 'location_name',
          filter: 'agSetColumnFilter',
          hide: false,
          cellStyle: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }
        },
        // Daily Velocity
        {
          headerName: t('product.daily_sales'),
          valueGetter: ({ data }) => {
            const dailyVelocity = data && data.daily_velocity !== undefined ? +data.daily_velocity : null
            const salesPrice = data && data.sell_price !== undefined ? +data.sell_price : null
            return { dailySales: dailyVelocity * salesPrice, dailyVelocity }
          },
          cellRenderer: dailyVelocityRenderer,
          // filterParams: {
          //   defaultOption: 'greaterThan',
          //   cellRenderer: dailyVelocityFilterRenderer
          // },
          // // aggFunc: 'sum',
          // filter: 'agSetColumnFilter',
          cellStyle: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }
        },
        // Day inventory
        {
          headerName: t('product.day_inventory'),
          hide: false,
          valueFormatter: ({ value }) => {
            return value !== null ? integerFormatter.format(value) : ''
          },
          cellStyle: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          },
          valueGetter: ({ data }) => data && data.day_inventory !== undefined ? +data.day_inventory : null,
          filter: 'agNumberColumnFilter',
          filterParams: {
            defaultOption: 'lessThan'
          },
          maxWidth: 150
        },
        // Sell Price
        {
          headerName: t('product.sell_price'),
          hide: true,
          field: 'sell_price',
          filter: 'agNumberColumnFilter',
          filterParams: {
            defaultOption: 'greaterThan'
          },
          cellStyle: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }
        },
        // Loss Sales
        {
          headerName: t('product.loss_sales'),
          valueFormatter: ({ value }) => currencyFormatter.format(value),
          valueGetter: ({ data }) => data && data.loss_sales !== undefined ? +data.loss_sales : null,
          filter: 'agNumberColumnFilter',
          aggFunc: 'sum',
          filterParams: {
            defaultOption: 'greaterThan'
          },
          hide: true,
          cellStyle: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }
        },
        // Supplier
        {
          headerName: t('product.supplier'),
          field: 'product_supplier',
          filter: 'agSetColumnFilter',
          hide: true,
          cellStyle: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }
        },
        // Next Order Date
        {
          headerName: t('product.next_reorder_date'),
          field: 'next_order_date',
          filter: 'agDateColumnFilter',
          filterParams: dateFilterParams,
          hide: true,
          valueGetter: ({ data }) => {
            return data && data.day_inventory !== undefined ? getNextOrderDate({ inventoryStatus: data.inventory_status, dayInventory: data.day_inventory, minDaysInventory: data.min_days_inventory, moq: data.moq, lastUpdate }) : null
          },
          cellStyle: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }
        },
        // Quantity to Order
        {
          headerName: t('product.qty_to_order'),
          valueFormatter: ({ value }) => {
            return value !== null ? integerFormatter.format(value) : ''
          },
          hide: false,
          valueGetter: ({ data }) => {
            return data && data.daily_velocity !== undefined ? getQuantityToOrder({ inventoryStatus: data.inventory_status, dailyVelocity: data.daily_velocity, minDaysInventory: data.min_days_inventory, leadTime: data.lead_time, moq: data.moq }) : null
          },
          filter: 'agNumberColumnFilter',
          filterParams: {
            defaultOption: 'greaterThan'
          },
          cellStyle: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          },
          maxWidth: 150
        },
        // Available Quantity
        {
          headerName: t('product.qty_available'),
          hide: true,
          valueFormatter: ({ value }) => {
            return value !== null ? integerFormatter.format(value) : ''
          },
          valueGetter: ({ data }) => data && data.inventory_quantity !== undefined ? +data.inventory_quantity : null,
          filter: 'agNumberColumnFilter',
          filterParams: {
            defaultOption: 'lessThan'
          },
          cellStyle: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }
        },
        // Minimum Days of Inventory
        {
          headerName: t('product.min_days_inventory'),
          hide: true,
          field: 'min_days_inventory',
          filter: 'agNumberColumnFilter',
          filterParams: {
            defaultOption: 'greaterThan'
          },
          cellStyle: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }
        },
        // Item type
        {
          headerName: t('product.is_material'),
          field: 'is_material',
          hide: true,
          cellRenderer: ({ value, node }) => {
            if (node.group) {
              return ''
            }
            return value ? t('product.material') : t('product.product')
          },
          cellStyle: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          },
          filter: 'agSetColumnFilter',
          filterParams: {
            valueFormatter: params => params.value ? t('product.material') : t('product.product'),
            values: [true, false]
          },
          valueFormatter: ({ value, node }) => {
            if (node.group) {
              return ''
            }
            return value ? t('product.material') : t('product.product')
          }
        },
        // Tracked Quantity
        {
          headerName: t('product.is_inventory_tracked'),
          hide: true,
          valueGetter: p => p.data && p.data.is_inventory_tracked === 't',
          colId: 'is_inventory_tracked',
          cellDataType: 'boolean',
          filter: 'agSetColumnFilter',
          cellStyle: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }
        },
        // Status
        {
          headerName: t('product.product_status'),
          hide: true,
          field: 'product_status',
          filter: 'agSetColumnFilter',
          cellStyle: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }
        },
        // Product title
        {
          headerName: t('product.name'),
          cellStyle: { textAlign: 'left' },
          field: 'product_name',
          filter: 'agSetColumnFilter',
          hide: true
        },
        // Variant title
        {
          headerName: t('product.variant'),
          cellStyle: { textAlign: 'left' },
          field: 'product_variant_name',
          filter: 'agSetColumnFilter',
          hide: true
        }
      ]
    },
    [t, lastUpdate, companyParams]
  )

  const openModalSoon = () => {
    if (replenishSoonData.product_list.length > 0) {
      expandGroup('replenish_soon')
    }
  }
  const openModalnow = () => {
    if (replenishNowData.product_list.length > 0) {
      expandGroup('replenish_now')
    }
  }
  const openModalOk = () => {
    if (ok.product_list.length > 0) {
      expandGroup('ok')
    }
  }
  const openModalOver = () => {
    if (overStockData.product_list.length > 0) {
      expandGroup('overstock')
    }
  }
  const openModalDead = () => {
    if (deadStockData.product_list.length > 0) {
      expandGroup('deadstock')
    }
  }
  const isValidNumber = (number) => number !== null && number >= 0

  const { secondaryDescription, secondaryDescriptionVars } = useMemo(() => {
    if ((replenishSoonData?.total_products + replenishNowData?.total_products - FREE_PLAN_LIMIT) > 0) {
      return {
        secondaryDescription: t('tooltip.unlock_more_data_secondary_description'),
        secondaryDescriptionVars: [replenishNowData?.total_products + replenishSoonData?.total_products - FREE_PLAN_LIMIT]
      }
    }
    return { secondaryDescription: null, secondaryDescriptionVars: null }
  }, [replenishSoonData?.total_products, replenishNowData?.total_products, t])

  return (
    <Page
      title={t('sidebar.replenishment')}
      subtitle={ lastUpdate && (
        <Box minHeight="40px">
          <Tooltip
            content={
              <Box padding='200' paddingBlockStart='300' paddingBlockEnd='300'>
                <BlockStack gap='300'>
                  <Text variant="headingMd">
                    {t('common.updated').slice(0, -1)}
                  </Text>
                  <Text>
                    {isFreeSubscriptionPlan ? t('tooltip.data_refresh_daily') : t('tooltip.data_refresh_hourly')}
                  </Text>
                </BlockStack>
              </Box>
                }
            hasUnderline
            preferredPosition='below'
              >
            <Text variant="headingMd" as="span">
              {t('common.updated')} {format(new Date(lastUpdate), 'yyyy-MM-dd H:mm')}
            </Text>
          </Tooltip>
        </Box>
      )}
      secondaryActions={
        <UpgradeButton
          tooltip={t('tooltip.unlock_unlimited_replenishment')}
          hidden={!isFreeSubscriptionPlan || !isUpgradeBannerDismissed}
        />
      }
      fullWidth={true}
    >
      <BlockStack gap='400'>
        {isFreeSubscriptionPlan && (
          <UpgradeBanner
            tone='info'
            title={t('tooltip.unlock_more_data')}
            description={t('tooltip.unlock_more_data_description')}
            secondaryDescription={secondaryDescription}
            secondaryDescriptionVars={secondaryDescriptionVars}
            onDismissCallback={() => setIsUpgradeBannerDismissed(true)}
          />
        )}
        {showBanner && (
          <div>
            <Box paddingBlock='400'>
              <Banner
                title={t('banner.job_status')}
                onDismiss={() => setShowBanner(false)}
                tone='info'
              >
                <Box paddingBlockStart='400' paddingBlockEnd='400'>
                  <ProgressBar tone='primary' progress={progress} />
                </Box>
              </Banner>
            </Box>
          </div>
        )}
        {showErrorBanner && (
          <div>
            <Box paddingBlock='400'>
              <Banner
                title={t('error_banner.title')}
                onDismiss={() => setShowErrorBanner(false)}
                tone='warning'
                action={{
                  content: t('error_banner.button'),
                  url: HELP_DESK_URL
                }}
              >
                <Box paddingBlockStart='400' paddingBlockEnd='400'>
                  {t('error_banner.content')}
                </Box>
              </Banner>
            </Box>
          </div>
        )}
        <Box maxWidth='258px' paddingBlockStart='200' paddingInlineStart='500'>
          <Select
            label={t('button.inventory')}
            labelInline
            options={options}
            onChange={handleSelectChange}
            value={selectedLocation}
            open={dropdownOpen}
            onClick={toggleDropdownOpen}
          />
        </Box>
        <Box padding='500'>
          <InlineGrid columns={5} gap='800'>
            {replenishNowData && (
              <InventoryCard
                title={t('dashboard.replenish_now')}
                totalSellPrice={replenishNowData.total_sell_price}
                totalProducts={replenishNowData.total_products}
                description={t('dashboard.replenish_now')}
                tone='critical'
                onCardClick={openModalnow}
                tooltipStatus={t('tooltip.replenish_now')}
                tooltipVars={
                  [
                    isValidNumber(companyParams.replenishNowDayThreshold) ? companyParams.replenishNowDayThreshold : REPLENISHMENT_NOW_DAY_THRESHOLD,
                    isValidNumber(companyParams.dailyVelocityThreshold) ? companyParams.dailyVelocityThreshold : DAILY_VELOCITY_THRESHOLD
                  ]
                }
              />
            )}
            {replenishSoonData && (
              <InventoryCard
                title={t('dashboard.replenish_soon')}
                totalSellPrice={replenishSoonData.total_sell_price}
                totalProducts={replenishSoonData.total_products}
                description={t('dashboard.replenish_soon')}
                tone='caution'
                onCardClick={openModalSoon}
                tooltipStatus={t('tooltip.replenish_soon')}
                tooltipVars={
                  [
                    isValidNumber(companyParams.replenishSoonDayThreshold) ? companyParams.replenishSoonDayThreshold : REPLENISHMENT_SOON_DAY_THRESHOLD,
                    isValidNumber(companyParams.dailyVelocityThreshold) ? companyParams.dailyVelocityThreshold : DAILY_VELOCITY_THRESHOLD
                  ]
                }
              />
            )}
            {ok && (
              <InventoryCard
                title={t('dashboard.ok')}
                totalProducts={ok.total_products}
                description={t('dashboard.ok')}
                tone='magic'
                onCardClick={openModalOk}
              />
            )}
            {overStockData && (
              <InventoryCard
                title={t('dashboard.overstock')}
                totalProducts={overStockData.total_products}
                description={t('dashboard.overstock')}
                tone='magic'
                onCardClick={openModalOver}
                tooltipStatus={t('tooltip.overstock')}
              />
            )}
            {deadStockData && (
              <InventoryCard
                title={t('dashboard.deadstock')}
                totalProducts={deadStockData.total_products}
                description={t('dashboard.deadstock')}
                tone='magic'
                onCardClick={openModalDead}
                tooltipStatus={t('tooltip.deadstock')}
              />
            )}
          </InlineGrid>
        </Box>
      </BlockStack>
      <div className='exports-container'>
        <div className='button-items-section'>
          <Box padding="500" paddingBlockStart='400'>
            <InlineStack gap="200" wrap={false} blockAlign="center">
              { isFreeSubscriptionPlan &&
              <Tooltip
                content={
                  <Box padding='200' paddingBlockStart='300' paddingBlockEnd='300'>
                    <BlockStack gap='300'>
                      <Text variant="headingMd">
                        {t('tooltip.unlock')}
                      </Text>
                    </BlockStack>
                  </Box>
                    }
                hasUnderline
                preferredPosition='below'
                  >
                <Icon
                  source={LockIcon}
                  tone="caution"
                    />
              </Tooltip>
                }
              <ExportButton gridRef={gridApi} disabled={isFreeSubscriptionPlan} />
              <ResetButton gridRef={gridApi} />
            </InlineStack>
          </Box>
        </div>
      </div>
      <div className='items-section obius-grid-size-m'>
        <AgGridTable
          colDefs={columns}
          rowData={products}
          gridOptions={gridOptions}
          initialGroupOrderComparator={initialGroupOrderComparator}
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
          defaultColDef={defaultColDef}
        />
      </div>
    </Page>
  )
}

export default ReplenishmentView
